// 不需要登录验证的地址
export const noLoginArr = [
  '/demo',
  '/h5',
  '/citizen-phone',
  '/online',
  '/about-us-login',
  '/radar-detail',
  '/report-detail',
  '/knowledge-detail',
  '/encyclopedia-knowledge',
  '/report',
  '/app-application',
  '/river',
]

// 不需要权限验证的地址
export const noVerifyArr = [
  ...noLoginArr,
]

// 有权限验证的地址
export const verifyArr = [
  ...noVerifyArr
]
