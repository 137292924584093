import api from '../../utils/api'

// 登录
export async function login (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         nickname: '张三',
  //         token: '34dweferfrewgrwgrwgrgrw'
  //       }
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/users/session', payload)
}

// 退出
export async function logout () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/users/session/delete')
}

// 登录短信
export async function sms (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/users/sms', payload)
}

// 医生id登录
export async function doctor_login (payload) {
  return api.post('/api/v2/users/session/embed', payload)
}
