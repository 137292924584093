// 用户信息
export function removeSession (key = 'session') {
  localStorage.removeItem(key)
}

export function setSession (session, key = 'session') {
  localStorage.setItem(key, JSON.stringify(session))
}

export function getSession (key = 'session') {
  return JSON.parse(localStorage.getItem(key))
}

export function setPersonalData (key, value) {
  const authToken = getSession()
  if(authToken){
    const id = authToken.user_id
    const newKey = `personal_${id}_${key}`
    localStorage.setItem(newKey, JSON.stringify(value))
  }
}

export function getPersonalData(key) {
  const authToken = getSession()
  if(authToken){
    const id = authToken.user_id
    const newKey = `personal_${id}_${key}`
    return JSON.parse(localStorage.getItem(newKey))
  }
}

export function removePersonalData(key) {
  const authToken = getSession()
  if(authToken){
    const id = authToken.user_id
    const newKey = `personal_${id}_${key}`
    localStorage.removeItem(newKey)
  }
}
